<template>
  <v-container class="py-7">
    <div class="heading mb-7">Редактор профиля</div>

    <v-text-field
      class="mb-4"
      label="Ваше имя"
      v-model="firstName"
      outlined
      :rules="[(v) => !!v || 'Укажите имя']"
      hide-details
    />

    <v-text-field
      class="mb-4"
      label="Ваша Фамилия"
      v-model="lastName"
      outlined
      hide-details
    />

    <v-dialog
      ref="dialog"
      v-model="dateModal"
      :return-value.sync="birthday"
      persistent
      width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="birthday"
          clearable
          label="Дата рождения"
          readonly
          outlined
          v-bind="attrs"
          v-on="on"
          @click:clear="birthday = null"
        >
        </v-text-field>
      </template>
      <v-date-picker v-model="formattedDate" :max="maxDate">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="dateModal = false"> Отмена </v-btn>
        <v-btn text color="primary" @click="$refs.dialog.save(birthday)">
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import actionButtonMixin from '@/mixins/actionButton'
import { mdiChevronRight, mdiCalendar } from '@mdi/js'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

export default {
  name: 'ProfileEdit',

  mixins: [actionButtonMixin],

  data: () => ({
    icons: {
      mdiChevronRight,
      mdiCalendar,
    },
    dateModal: false,
    loading: false,
    firstName: '',
    lastName: '',
    birthday: '',
    hasBirthday: false,
  }),

  computed: {
    actionButton() {
      return {
        active: true,
        action: this.save,
        text: 'Сохранить',
        loading: this.loading,
      }
    },

    isDarkTheme() {
      return this.$vuetify.theme.isDark
    },

    formattedDate: {
      get() {
        if (this.birthday) {
          const arr = this.birthday?.split('.')
          return arr?.reverse().join('-') || ''
        } else {
          return dayjs().subtract(18, 'year').format('YYYY-MM-DD')
        }
      },
      set(value) {
        this.birthday = dayjs(value).format('DD.MM.YYYY')
      },
    },

    maxDate() {
      return dayjs().subtract(3, 'year').format('YYYY-MM-DD')
    },

    ...mapState('user', ['profile']),
  },

  methods: {
    async save() {
      const { commit, dispatch } = this.$store
      this.loading = true
      const params = {
        first_name: this.firstName,
        last_name: this.lastName,
        contact_phone: this.profile?.contact_phone,
        birthday: this.birthday,
      }
      const response = await dispatch('user/saveProfile', params)
      await dispatch('user/loadProfile')
      const text =
        response.code == 1
          ? 'Профиль успешно обновлен'
          : response.msg || 'Не удалось обновить профиль'
      commit('snackbar/update', { active: true, text })
      this.loading = false
      this.$router.back()

      const birthday = dayjs(params.birthday, 'DD.MM.YYYY')

      this.$emitter.emit('updateUserProfile', {
        name: params.first_name,
        birthday: birthday.isValid() ? birthday.format('YYYY-MM-DD') : '',
        phone: params.contact_phone,
      })
    },
  },

  async created() {
    const { commit, dispatch } = this.$store
    commit('overlay/update', { active: true })
    await dispatch('user/loadProfile')
    commit('overlay/update', { active: false })
    this.firstName = this.profile?.first_name
    this.lastName = this.profile?.last_name

    const birhday = this.profile?.birthday
    if (birhday) {
      this.birthday = birhday
      this.hasBirthday = true
    }
  },

  mounted() {
    if (this.isDarkTheme) {
      const html = document.querySelector('html')
      html.classList.add('theme--dark')
    }
  },
}
</script>
